.body {
    margin: 0px !important;
}
div {
    font-size: 17px ;
}

/* disable horizontal scroll */
.ScrollbarsCustom-TrackY {
    display: none;
}
