.cs-message--incoming .cs-message__sender-name,
.cs-message--incoming .cs-message__sent-time,
.cs-message--outgoing .cs-message__sent-time,
.cs-message--outgoing .cs-message__sender-name {
  display: block;
}
.cs-message--incoming .cs-message__content {
  background-color: #ffcece !important;
}
.cs-message--outgoing .cs-message__content {
  background-color: #fa5252 !important;
  color: white !important;
}
.cs-message-input__content-editor-wrapper {
  background-color: #fa5252 !important;
  color: white !important;
}

.cs-message-input__content-editor {
  background-color: #fa5252 !important;
  color: white;
}
.cs-button--send {
  color: #ea4646 !important;
}
.cs-message__sender-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
